@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

$family-sans-serif: "Inria Serif", serif;

@import "~bulma/bulma";

p {
  font-style: italic;
  font-weight: lighter;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  line-height: 16.2px;
  color: black;
}

.intro-text {
  margin: 40px auto;

  .follow-us-container {
      display: flex;
      justify-content: space-around;
      margin-top: 1.2rem;

    .follow-us {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }

      p {
        text-align: left;
        font-weight: bold;
        line-height: 1.1;
        margin-left: 0.4rem;
      }
    }
  }
}

.video-container {
  max-width: 1000px;
}
.card {
  .card-content {
    padding: 1rem;

    .content {
      position: relative;

      p {
        font-size: 25px;
        margin-bottom: 0;
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 20px;

        svg {
          height: 20px;
        }
      }
    }
  }
}

.modal {
  .modal-card-body p {
    font-size: 18px;
    line-height: 1.6;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#video {
  width: 100%;
  height: 100%;
  position: relative;

  .close {
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 10;

    p {
      color: white;
      font-size: 30px;
      line-height: 1;
      text-shadow: 2px 2px #333;
      cursor: pointer;
    }
  }

  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .columns {
    display: flex;
  }
}

@media only screen and (min-width: 1024px) {
  .intro-text {
    p {
      font-size: 17.5px;
      line-height: 28.5px;
    }
  }
}
